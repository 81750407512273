body {
  font-family: 'JetBrains Mono', monospace;
  background-color: #1a1b1e;
}

.App {
  padding: 200px;
  color: lightyellow;
}

.theBall,
.theBall-outer {
  mix-blend-mode: exclusion;
  width: 180px;
  height: 180px;
}

.theBall-outer {
  position: fixed;
  top: -100px;
  left: -100px;
  right: -100px;
  bottom: -100px;
  z-index: 5000;
  pointer-events: none !important;
}

.theBall {
  position: absolute;
  background-color: lightyellow;
  border-radius: 50%;
  -webkit-transition: transform .2s cubic-bezier(.175, .885, .32, 1.275);
  -moz-transition: transform .2s cubic-bezier(.175, .885, .32, 1.275);
  -ms-transition: transform .2s cubic-bezier(.175, .885, .32, 1.275);
  -o-transition: transform .2s cubic-bezier(.175, .885, .32, 1.275);
  transition: transform .2s cubic-bezier(.175, .885, .32, 1.275);
  transform-origin: center center;
}

.dark_page .theBall,
.coloring .theBall {
  background-color: lightyellow;
}

a {
  color: lightyellow;
  cursor: pointer;
}

h1 {
  font-weight: 700;
}

sub {
  font-size: 10px;
}
